@import "../../styles/theme/settings";
@import "~foundation-sites/scss/xy-grid/xy-grid";

$-zf-flex-justify: -zf-flex-justify(ltr);
@import "~foundation-sites/scss/util/flex";

.Cell {
  max-width: 100%;
  @include xy-cell-base(full);
  &.auto {
    @include xy-cell-base(auto);
  }
  &.shrink {
    @include xy-cell-base(shrink);
  }
  &.grow {
    @include xy-cell-base(grow);
  }
  &.container {
    @include xy-cell-block-container;
  }
  &.block {
    &.horizontal {
      @include xy-cell-block();
    }
    &.vertical {
      @include xy-cell-block(true);
    }
  }
  &.centered {
    @include flex();
    @include flex-align();
    min-height: 100%;
    &.horizontal {
      @include flex-align(center, null);
    }
    &.vertical {
      @include flex-align(null, middle);
    }
    &.horizontal.vertical {
      @include flex-align(center, middle);
    }
  }
}

