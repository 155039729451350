@import "../../styles/theme/settings";
@import "~foundation-sites/scss/xy-grid/xy-grid";

.Grid {
  &.horizontal {
    @include xy-grid(horizontal);
  }
  &.vertical {
    @include xy-grid(vertical);
  }
}