@import "../../styles/theme/settings";

.Header {
  box-shadow: 0 .1rem .2rem rgba(0, 0, 0, .3);
  padding: $titlebar-padding;
  position: relative;
  z-index: 1;
  h1, h2, h3, h4, h5, p {
    padding: 0 $titlebar-padding;
    margin-bottom: .25rem;
  }
}