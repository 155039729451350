@import "../../../styles/theme/settings";

.Welcome {

  $-zf-flex-justify: -zf-flex-justify(ltr);
  @import "~foundation-sites/scss/util/flex";

  @include flex();
  @include flex-direction(column);
  @include flex-align(space-around, middle);

  height: 100%;
  text-align: center;
  padding: $global-padding;
  .room-logo {
    height: 20vh;
    &:only-child {
      height: 50vh;
    }
  }
  .guest-picture {
    height: 24vh;
    margin: 4vh 0 2vh;
  }
  h1, h2, h3, h4, h5, h6, p,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0;
    line-height: normal;
  }
  .Heading {
    font-weight: $global-weight-normal;
  }
  & > div {
    margin: $global-margin / 2 0;
  }
}