@import "../../styles/theme/settings";

.Entry {
  overflow: hidden;
  & > :last-child {
    margin-bottom: 0;
  }
  &:not(:last-child) {
    border-bottom: $hr-border;
    padding-bottom: $global-padding;
    margin-bottom: $global-margin;
  }
}