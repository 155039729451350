$arrow-size: 0.25rem;

.arrow {
  border: solid;
  border-width: 0 $arrow-size/2 $arrow-size/2 0;
  display: inline-block;
  padding: $arrow-size;
  vertical-align: middle;
  margin-top: $arrow-size * -1;

  &.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin-left: $arrow-size * -1;
  }

  &.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    margin-right: $arrow-size * -1;
  }

  &.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  &.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}
