@import "../../styles/theme/settings";

.Footer {
  box-shadow: 0 0 .2rem rgba(0, 0, 0, .3);
  padding: $titlebar-padding;
  position: relative;
  z-index: 1;
  & > .Grid {
    margin: $titlebar-padding * -1;
    & > .Cell {
      margin: $titlebar-padding;
    }
  }
}