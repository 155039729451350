@import "../../styles/theme/settings";

.Table {
  height: 100%;
  margin-bottom: 0;
  &.vertical {
    .TableRow {
      .TableCell {
        &.head {
          border-bottom: rem-calc(1) solid $white;
          width: 20%;
        }
      }
      &:nth-child(even) {
        .TableCell {
          &.data {
            background-color: $table-striped-background;
          }
        }
      }
    }
  }
  &.horizontal {
    table-layout: fixed;
    .TableCell {
      &.head {
        // firefox fix
        height: rem-calc(1);
      }
      &.data {
        &:nth-child(even) {
          background-color: $table-striped-background;
        }
      }
    }
  }
  .TableCell {
    &.active {
      &.head {
        background-color: map-get($foundation-palette, success);
      }
      &.data {
        //background-color: $table-row-stripe-hover;
      }
    }
  }
}