@import "settings";
@import "mixins/fonts";

@import "theme/settings";

$fonts-base-path: 'fonts/';
$font-faces: (
                ('Light', normal, 300),
                ('Regular', normal, 400),
                ('Semibold', normal, 600),
                ('Bold', normal, 700),
                ('Light Italic', italic, 300),
                ('Italic', italic, 400),
                ('Semibold Italic', italic, 600),
                ('Bold Italic', italic, 700)
);

@each $typeface, $style, $weight in $font-faces {
  @include font-face($fonts-base-path, $ui-font-family, $typeface, $style, $weight);
}


@each $name, $value in $foundation-palette {
  .color-#{$name} {
    color: $value;
  }
}