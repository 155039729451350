@import "../../../../styles/theme/settings";

.View {
  .TableCell {
    text-align: center;
    vertical-align: middle;
    &.active {
      background-color: $table-row-stripe-hover !important;
    }
  }
  &.Table {
    .TableCell {
      &.active {
        background-color: map-get($foundation-palette, primary) !important;
        h1, h2, h3, h4, h5, h6, p {
          color: map-get($foundation-palette, white);
        }
      }
    }
  }
  &.room-list,
  &.list-room {
    &.horizontal {
      & > .room {
        width: 60%;
      }
    }
    &.vertical {
      & > .room {
        height: 60%;
      }
    }
  }
}