@import "../../styles/theme/settings";
@import "~foundation-sites/scss/xy-grid/xy-grid";

$orientationMap: (
        horizontal: false,
        vertical: true
);

$levelMap: (
        root: false,
        nested: true
);

.GridFrame {
  @each $orientationName, $orientationValue in $orientationMap {
    @each $levelName, $levelValue in $levelMap {
      &.#{$orientationName} {
        &.#{$levelName} {
          @include xy-grid-frame($orientationValue, $levelValue);
        }
      }
    }
  }
  &.horizontal {
    height: 100%;
  }
}