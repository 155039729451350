@import "../../../../styles/theme/settings";

.Room {

  $tint-color: transparentize(map-get($foundation-palette, primary), .2);
  background: $tint-color;
  background: -moz-linear-gradient(top,$tint-color 0%, $tint-color 100%), url('../../../../images/bg.jpg');
  background: -webkit-linear-gradient(top, $tint-color 0%, $tint-color 100%), url('../../../../images/bg.jpg');
  background: linear-gradient(to bottom, $tint-color 0%, $tint-color 100%), url('../../../../images/bg.jpg');
  background-size: cover;
  background-position: 25% 50%;
  color: map-get($foundation-palette, white);

  text-align: center;

  .Current {
    padding: $global-padding;
    h1 {
      line-height: 1.2;
      word-break: break-all;
      word-break: break-word;
    }
    ul {
      &:before {
        content: '';
        border-top: rem-calc(1) solid map-get($foundation-palette, white);
        display: block;
        width: 80%;
        margin: $global-margin auto;
      }
    }
  }

  .PrevNext {
    position: relative;
    padding: $global-padding / 2;
    h1, h2, h3, h4, h5, h6, p {
      display: inline-block;
      margin: 0 $global-margin / 2;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
    }
    &.prev {
      border-bottom: rem-calc(1) solid map-get($foundation-palette, white);
    }
    &.next {
      border-top: rem-calc(1) solid map-get($foundation-palette, white);
    }
  }
}