@import "../../styles/theme/settings";

.TableCell {
  vertical-align: top;
  text-align: left;
}

th.TableCell {
  background-color: $table-head-background;
  color: $table-head-font-color;
  font-weight: $global-weight-normal;
  h1, h2, h3, h4, h5, h6, p {
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}