@media screen and (min-width: 1200px), screen and (min-height: 1200px) {
  html {
    font-size: 125%;
  }
}
@media screen and (min-width: 1800px), screen and (min-height: 1800px) {
  html {
    font-size: 175%;
  }
}
@media screen and (min-width: 2700px), screen and (min-height: 2700px) {
  html {
    font-size: 250%;
  }
}
@media screen and (min-width: 3600px), screen and (min-height: 3600px) {
  html {
    font-size: 350%;
  }
}