@import "../functions/utils";

@mixin font-face($path, $name, $typeface, $style: normal, $weight: 400) {
  $file-name: (str-replace($name, ' ', '')) + '-' + (str-replace($typeface, ' ', ''));
  @font-face {
    font-family: $name;
    font-style: $style;
    font-weight: $weight;
    src:
            local($name),
            local($name + " " + $typeface),
            local($file-name),
            url($path + "/" + $file-name + ".ttf");
  }
}